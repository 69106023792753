export default function initDropdownGrid() {
    const $dropdownContainers = document.querySelectorAll<HTMLElement>('[data-dropdown-grid]');
    $dropdownContainers.forEach($container => {
        new DropDownGrid($container);
    });
}

/**
 * Rend un élement accordéon
 * mettre data-dropdown-grid="mobile" ou data-dropdown-grid="desktop" pour une activation mobile ou desktop only
 * 
 * Utilisation :
 * <div data-dropdown-grid>
 *   <div data-dropdown-grid-button>Toggle menu</div>
 *   <div data-dropdown-grid-content>
 *   </div>
 * </div>
 */
export class DropDownGrid {

    $container: HTMLElement | null | undefined;
    $button: HTMLElement | null | undefined;
    $content: HTMLElement | null | undefined;
    mdBreakpoint: number;
    device: string;
    deviceParam: string;
    callEvent: (event: Event) => void;
    callEventOutside: (event: Event) => void;

    constructor($container: HTMLElement | null) {
        this.$container = $container;
        this.$button = $container?.querySelector('[data-dropdown-grid-button]');
        this.$content = $container?.querySelector('[data-dropdown-grid-content]');
        this.mdBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'));
        this.device = '';
        this.deviceParam = this.$container.dataset.dropdownGrid;
        this.callEvent = this.dropdownClickHandler.bind(this);
        this.callEventOutside = this.dropdownClickOutsideHandler.bind(this);

        this.init(this.deviceParam);
    }

    init(deviceParam) {
        if (deviceParam == 'mobile') {
            window.addEventListener('resize', this.initMobileOnly.bind(this));
            this.initMobileOnly();
        } else if (deviceParam == 'desktop') {
            window.addEventListener('resize', this.initDesktopOnly.bind(this));
            this.initDesktopOnly();
        } else {
            this.initDropDown();
        }
    }

    initMobileOnly() {
        if (this.device != 'desktop' && window.innerWidth > this.mdBreakpoint) {
            this.device = 'desktop';
            this.destroyDropDown();
        } else if (this.device != 'mobile' && window.innerWidth <= this.mdBreakpoint) {
            this.device = 'mobile';
            this.initDropDown();
        }
    }

    initDesktopOnly() {
        if (this.device != 'desktop' && window.innerWidth > this.mdBreakpoint) {
            this.device = 'desktop';
            this.initDropDown();
        } else if (this.device != 'mobile' && window.innerWidth <= this.mdBreakpoint) {
            this.device = 'mobile';
            this.destroyDropDown();
        }
    }

    /**
     * Initialise les éléments du dropdown
     *
     * @return {void}
     */
    initDropDown() {
        this.initAria();
        this.$button?.addEventListener('click', this.callEvent);
        this.$button?.addEventListener('keypress', this.callEvent);
        document.addEventListener('click', this.callEventOutside);

    }

    /**
     * Supprime les éléments du dropdown
     *
     * @return {void}
     */
    destroyDropDown() {
        this.deleteAria();
        this.$button?.removeEventListener('click', this.callEvent);
        this.$button?.removeEventListener('keypress', this.callEvent);
        document.removeEventListener('click', this.callEventOutside);

    }

    /**
     * Initialise les aria-expanded et aria hidden
     *
     * @return {void}
     */
    initAria() {
        this.$button?.setAttribute('tabindex', '0');
        this.$button?.setAttribute('aria-expanded', 'false');
        this.$content?.setAttribute('aria-hidden', 'true');
    }

    /**
     * Supprime les aria-expanded et aria hidden
     *
     * @return {void}
     */
    deleteAria() {
        this.$button?.removeAttribute('tabindex');
        this.$button?.removeAttribute('aria-expanded');
        this.$content?.removeAttribute('aria-hidden');
    }

    /**
     * Fonction au click sur le bouton dropdown
     *
     * @return {void}
     */
    dropdownClickHandler() {
        if (!this.$container?.classList.contains('open')) {
            this.openDropdown();
        } else {
            this.closeDropdown();
        }
    }

    /**
     * Fonction au click à l'exterieur du dropdown
     *
     * @return {void}
     */
    dropdownClickOutsideHandler(event: MouseEvent) {
        if (!this.$container.contains(event.target as Node)) {
            this.closeDropdown();
        }
    }

    /**
     * Ouvre le dropdown
     *
     * @return {void}
     */
    openDropdown() {
        this.$container?.classList.add('open');
        this.$button?.setAttribute('aria-expanded', 'true');
        this.$content?.setAttribute('aria-hidden', 'false');
    }

    /**
     * Ferme le dropdown
     *
     * @return {void}
     */
    closeDropdown() {
        this.$container?.classList.remove('open');
        this.$button?.setAttribute('aria-expanded', 'false');
        this.$content?.setAttribute('aria-hidden', 'true');
    }
}
