
export default function initTypeContent(): void {
    const $typeContentContainer = document.querySelectorAll<HTMLElement>('.block-type-content');

    $typeContentContainer.forEach($container => {
        new TypeContent($container);
    });
}

class TypeContent {
    $el: HTMLElement | null | undefined;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.init();
    }

    init() {
        const videoWrappers = this.$el?.querySelectorAll('.video-wrapper');
        if (videoWrappers) {
            videoWrappers.forEach(videoWrapper => {
                videoWrapper.addEventListener('click', ()=> {
                    videoWrapper.classList.add('video-wrapper--play');
                })
            })
        }
    }
}
