import Swiper from 'swiper';

export default function initCarouselActus() : void {
    const $carouselActusContainer = document.querySelectorAll<HTMLElement>('.block-carousel-actus');

    $carouselActusContainer.forEach($container => {
        new CarouselActu($container);
    });
}

class CarouselActu {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    $navigation_prev: HTMLElement | null | undefined;
    $navigation_next: HTMLElement | null | undefined;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.carousel-actus');
        this.init();
    }

    init(): void {
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                slidesPerView: 'auto',
                loop: false,
                allowTouchMove: true,
                spaceBetween: 25,
                grabCursor: true,

                breakpoints: {
                    768: {
                        spaceBetween: 40,
                    },
                },
            });
        }
    }
}
