export default function initTabsVertical() {
    const $blocksTabs = document.querySelectorAll<HTMLElement>('.block-tabs-vertical');
    $blocksTabs.forEach($container => {
        new BlockTabsVertical($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class BlockTabsVertical {
    $el: HTMLElement | null | undefined;
    $tabsContents: NodeListOf<HTMLElement> | null | undefined;
    $tabsButtons: NodeListOf<HTMLElement> | null | undefined;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$tabsContents = $el?.querySelectorAll('[data-tab]');
        this.$tabsButtons = $el?.querySelectorAll('[data-tab-target]');

        this.init();
    }

    init() {
        
        this.$tabsButtons.forEach($tabButton => {
            $tabButton.addEventListener('click', () => {
                const target = this.$el?.querySelector('[data-tab='+$tabButton.dataset.tabTarget+']');

                if (target) {
                    this.closeAllTabs();
                    target.classList.add('active');
                    $tabButton.classList.add('active');
                }
            })
        })
    }

    closeAllTabs() {
        this.$tabsButtons.forEach($tabButton => {
            $tabButton.classList.remove('active');
        })

        this.$tabsContents.forEach($tabContent => {
            $tabContent.classList.remove('active');
        })
    }
}
