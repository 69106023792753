import Swiper from 'swiper';

export default function initMaillagePostsSimilaires() : void {
    const $maillageActuContainer = document.querySelectorAll<HTMLElement>('.maillage-posts-similaires');

    $maillageActuContainer.forEach($container => {
        new MaillagePostsSimilaires($container);
    });
}

class MaillagePostsSimilaires {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.wrapper-articles');

        this.createSwiper();
    }

    /**
     * Créé le swiper
     */
    createSwiper(): void {
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                slidesPerView: 'auto',
                spaceBetween: 24,
                loop: false,
                allowTouchMove: true,
                grabCursor: true,
                breakpoints: {
                    768: {
                        spaceBetween: 60,
                    },
                },
            });
        }
    }
}
