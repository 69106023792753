import FocusManager from '../utils/focus-manager';
import ScrollManager from '../utils/scroll-manager';
import { uniqid } from '../utils/utils';

type ModalAction = 'open'|'close'|'toggle';

export interface ModalHTMLElement extends HTMLElement {
    open() : void;
    close() : void;
    toggle() : void;
    on(action: ModalAction, fn: CallableFunction) : void;
    isOpen() : boolean;
}

export default function initModals() : void {
    document.querySelectorAll<ModalHTMLElement>('[data-modal-id]').forEach($modal => {
        const modalId = $modal.dataset.modalId;

        if (modalId && window.location.hash === `#${modalId}`) {
            const modalInstance = new Modal($modal);
            modalInstance.open(); // Ouvre la modal si l'ancre correspond à son ID
        } else {
            new Modal($modal);
        }
    });
}

declare global {
    interface DocumentEventMap {
        'modalopen': CustomEvent<ModalHTMLElement>;
        'modalclose': CustomEvent<ModalHTMLElement>;
    }
}

class Modal {
    $modal: ModalHTMLElement;
    $siteContainer: HTMLElement;
    callbacks: {[key in ModalAction]: CallableFunction[]} = { open: [], close: [], toggle: [] };
    intentExitAlreadyClosed = false;
    intentScrollAlreadyClosed = false;
    urlLocation = window.location.toString();

    constructor($modal: ModalHTMLElement) {
        this.$modal = $modal;
        this.$siteContainer = document.querySelector('.site-container') as HTMLElement;

        this.$modal.open = this.open;
        this.$modal.close = this.close;
        this.$modal.toggle = this.toggle;
        this.$modal.on = this.on;
        this.$modal.isOpen = this.isOpen;

        // On retire le display none pour éviter la première animation de transition
        this.$modal.style.display = '';

        // Ouvre, Ferme, Toggle la modale quand on clique sur un bouton avec un attribut [data-modal-{action}]
        ['open', 'close', 'toggle'].forEach(action => {
            Array.from(document.querySelectorAll(`[data-modal-${action}="${this.$modal.dataset.modalId}"]`)).forEach($button => {
                // @ts-ignore
                $button.addEventListener('click', this[action]);
            });
        });

        // Ferme la modale quand on clique à l'extérieur de celle-ci
        this.$modal.addEventListener('click', (evt) => {
            if (evt.target !== this.$modal) {
                return;
            }

            this.close();
        });

        // Ferme la modale à l'appui sur la touche Echap
        document.addEventListener('keyup', (evt) => {
            if (this.isOpen() && evt.key === 'Escape') {
                this.close();
            }
        });
    }

    /**
     * Ouvre la modale
     */
    open = () : void => {
        if (this.isOpen()) {
            return;
        }

        this.$siteContainer.setAttribute('aria-hidden', 'true');

        this.$modal.classList.add('open');
        this.$modal.setAttribute('aria-hidden', 'false');

        ScrollManager.preventScroll(true);
        FocusManager.trapFocus([this.$modal]);

        // Vérouille la modale pendant 1s pour éviter de la refermer trop vite
        this.$modal.classList.add('locked');
        setTimeout(() => {
            this.$modal.classList.remove('locked');
        }, 1000);

        // Exécute les callbacks enregistrés sur cette action
        this.callbacks.open.forEach(fn => fn());

        // Exécute les callbacks enregistrés sur cette action
        this.callbacks.open.forEach(fn => fn());

        // Push state pour tracking modal
        const obj = {Page: '', Url: '#' + this.$modal.dataset.modalId }
        history.pushState(obj, obj.Page, obj.Url);

        document.dispatchEvent(new CustomEvent('modalopen', { detail: this.$modal }));
    }

    /**
     * Ferme la modale
     */
    close = () : void => {
        if (!this.isOpen() || this.$modal.classList.contains('locked')) {
            return;
        }

        document.dispatchEvent(new CustomEvent('modalclose', { detail: this.$modal }));

        this.$siteContainer.setAttribute('aria-hidden', 'false');

        this.$modal.classList.remove('open');
        this.$modal.setAttribute('aria-hidden', 'true');

        ScrollManager.preventScroll(false);
        FocusManager.untrapFocus();

        // Push state clear
        history.pushState('', '', this.urlLocation);

        // Exécute les callbacks enregistrés sur cette action
        this.callbacks.close.forEach(fn => fn());
    }

    /**
     * Toggle la modale
     */
    toggle = () : void => {
        if (this.$modal.classList.contains('locked')) {
            return;
        }

        if (this.isOpen()) {
            this.open();
        } else {
            this.close();
        }

        // Exécute les callbacks enregistrés sur cette action
        this.callbacks.toggle.forEach(fn => fn());
    }

    /**
     * Enregistre un callback sur une action
     */
    on = (action: ModalAction, fn: CallableFunction) : void => {
        this.callbacks[action].push(fn);
    }

    /**
     * Check si la modale est ouverte
     */
    isOpen = () : boolean => this.$modal.classList.contains('open');

    /**
     * Check si la modale est vérouillée
     */
    isLocked = () : boolean => this.$modal.classList.contains('locked');

    /**
     * Retourne l'ID de la modale
     */
    getModalId = () : string => this.$modal.getAttribute('data-modal-id') || uniqid();
}
