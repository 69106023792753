export default {
    /**
     * Incrémenté par la méthode preventScroll()
     * Le scroll est vérouillé lorsque la valeur est supérieure à 0
     */
    preventScrollCounter: 0,
    
    /**
     * Vérouille / dévérouille le scroll de la page
     * 
     * @param state true = vérouillé / false = dévérouillé
     */
    preventScroll(state: boolean) : typeof self {
        if (state) {
            this.preventScrollCounter++;
        } else if (this.preventScrollCounter > 0) {
            this.preventScrollCounter--;
        }

        // Toggle la classe "prevent-scroll" sur le body si le compteur est plus supérieur à 0
        document.body.classList.toggle('prevent-scroll', this.preventScrollCounter > 0);

        return self;
    },

    /**
     * Scroll jusqu'à un élément
     * 
     * @param $element 
     * @param smooth
     */
    scrollTo($element: HTMLElement|number, smooth = true) : typeof self {
        const posY = typeof $element === 'number' ? $element : $element.getBoundingClientRect().top + window.scrollY;

        if (!smooth) {
            // Supprime la propriété CSS "scrollBehavior"
            document.documentElement.style.scrollBehavior = 'initial';
            document.body.style.scrollBehavior = 'initial';
        }

        window.scroll(0, posY);

        if (!smooth) {
            // Reset de la propriété CSS "scrollBehavior"
            document.documentElement.style.scrollBehavior = '';
            document.body.style.scrollBehavior = '';
        }

        return self;
    },
};
