export default function initDropdown() {
    const $dropdownContainers = document.querySelectorAll<HTMLElement>('.js-dropdown-container');
    $dropdownContainers.forEach($container => {
        new DropDown($container);
    });
}

/**
 * Rend un élement accordéon
 * 
 * Utilisation :
 **/
/* 
<div class="js-dropdown-container">
<button class="js-dropdown-button">Toggle menu</button>
<div class="js-dropdown-content">
</div>
</div> 
*/
 
export class DropDown {

    $container: HTMLElement | null | undefined;
    $button: HTMLElement | null | undefined;
    $content: HTMLElement | null | undefined;

    constructor($container: HTMLElement | null) {
        this.$container = $container;
        this.$button = $container?.querySelector('.js-dropdown-button');
        this.$content = $container?.querySelector('.js-dropdown-content');

        this.init();
    }

    /**
     * Initialise les éléments du dropdown
     *
     * @return {void}
     */
    init() {
        this.$button?.addEventListener('click', () => {
            if (!this.$container?.classList.contains('open')) {
                this.closeAllOpenDropdowns();
                this.openDropdown();
            } else {
                this.closeDropdown();
            }

            document.addEventListener('modalclose', (evt) => {
                const allDropdowns = evt.detail.querySelectorAll('.js-dropdown-container');
                allDropdowns?.forEach((dropdown) => {
                    const button = dropdown.querySelector('.dropdown-button');
                    const content = dropdown.querySelector('.dropdown-content')
                    dropdown.classList.remove('open');
                    button?.setAttribute('aria-expanded', 'false');
                    content?.setAttribute('aria-hidden', 'true');
                })
            });
        });
    }

    /**
     * Ouvre le dropdown
     *
     * @return {void}
     */
    openDropdown() {
        this.$container?.classList.add('open');
        this.$button?.setAttribute('aria-expanded', 'true');
        this.$content?.setAttribute('aria-hidden', 'false');
    }

    /**
     * Ferme le dropdown
     *
     * @return {void}
     */
    closeDropdown() {
        this.$container?.classList.remove('open');
        this.$button?.setAttribute('aria-expanded', 'false');
        this.$content?.setAttribute('aria-hidden', 'true');
    }

    closeAllOpenDropdowns(){
        const wrapper = this.$container?.parentNode;
        const allDropdowns = wrapper?.querySelectorAll('.js-dropdown-container');
        allDropdowns?.forEach((dropdown) => {
            const button = dropdown.querySelector('.dropdown-button');
            const content = dropdown.querySelector('.dropdown-content')
            dropdown.classList.remove('open');
            button?.setAttribute('aria-expanded', 'false');
            content?.setAttribute('aria-hidden', 'true');
        })
    }
}

