import { toggleClassWhenSticky } from "../utils/toggle-class-when-sticky";

export default function initSingleContentSummary() : void {
    const $summaryContainer = document.querySelectorAll<HTMLElement>('.js-wrapper-summary');

    $summaryContainer.forEach($container => {
        new SingleContentSummary($container);
    });
}

class SingleContentSummary {
    $el: HTMLElement | null | undefined;
    $links: NodeListOf<HTMLElement> | null | undefined;
    $summary: HTMLElement | null | undefined;
    $toggleOpenNavigationMobile: NodeListOf<HTMLElement> | null | undefined;
    $currentAnchor: HTMLElement | null | undefined;
    mdBreakpoint: number;
    options: object | null;
    device: string | null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$links = $el?.querySelectorAll('a[href^="#"]');
        this.$summary = $el?.querySelector('.js-summary');
        this.$toggleOpenNavigationMobile = $el?.querySelectorAll('.js-toggle-open-summary');
        this.$currentAnchor = $el?.querySelector('.js-current-anchor');
        this.mdBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'));
        this.device = 'desktop';

        this.init();
        
        // Options pour l'Intersection Observer
        this.options = {
            root: null, // Le viewport
            rootMargin: '0px', // Marge par défaut
            threshold: 0.5, // Dès que 50% de l'élément d'ancre est visible
        };
  
    }

    /**
     * Init Global
     */
    init(): void {
        // Créez un Intersection Observer avec le callback
        const observer = new IntersectionObserver(this.handleIntersection.bind(this), this.options);

        // Ajoutez les éléments d'ancre à l'Intersection Observer
        this.$links.forEach((link) => {
            const target = document.querySelector(link.getAttribute('href'));
            if (target) {
                observer.observe(target);
            }
        });

        // Mobile sticky wrapper summary
        toggleClassWhenSticky(this.$el, 'wrapper-summary-sticky');

        // Toggle ouverture/fermeture navigation summary en mobile
        this.$toggleOpenNavigationMobile.forEach($item => {
            $item.addEventListener('click', () => {
                if ($item.classList.contains('summary-link')) {
                    this.$summary.classList.remove('open');
                    this.$el.classList.remove('open');
                } else {
                    this.$summary.classList.toggle('open');
                    this.$el.classList.toggle('open');
                }
            });
        })
    }

    // Créez un callback pour gérer les entrées de l'Intersection Observer
    handleIntersection(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // L'élément d'ancre est visible, ajoutez la classe 'active'
                const targetId = entry.target.getAttribute('id'); // Récupérez l'ID de l'ancre
                this.$links.forEach((item) => {
                    item.classList.remove('active'); // Supprimez la classe 'active' de tous les éléments de navigation
                    if (item.getAttribute('data-target') === targetId) {
                        this.$currentAnchor.innerHTML = item.innerHTML;
                        item.classList.add('active'); // Ajoutez la classe 'active' à l'élément de navigation correspondant
                    }
                });
            }
        });
    }
}
