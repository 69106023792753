export default function initAppearAnim() {
    const $appearAnimElements = document.querySelectorAll('.anim-element');

    $appearAnimElements.forEach($element => {
        new AppearAnim($element);
    });
}

/**
 * add class appear on element with data-appear-anim
 *
 * @param {HTMLElement} $el HTML Element
 */
class AppearAnim {
    $element: Element;
    constructor($element: Element) {
        this.$element = $element;
        this.onIntersection = this.onIntersection.bind(this);
        const observer = new IntersectionObserver(this.onIntersection);
        observer.observe(this.$element);
    }

    /**
     * @param {IntersectionObserverEntry[]} entries
     */
    onIntersection(entries: IntersectionObserverEntry[]) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.$element.classList.add('appear');
            }
        });
    }
}
