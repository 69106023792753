import { toggleClassWhenSticky } from "../utils/toggle-class-when-sticky";

export default function initNav(): void {
    const $navContainer = document.querySelectorAll<HTMLElement>('.js-main-header');

    $navContainer.forEach($container => {
        new Nav($container);
    });
}

class Nav {
    $body: HTMLElement | null | undefined;
    $el: HTMLElement | null | undefined;
    navBreakpoint: number;

    constructor($el: HTMLElement | null) {
        this.$body = document.querySelector('body');
        this.$el = $el;
        this.navBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-nav'));
        window.addEventListener('resize', this.onWindowResize.bind(this));
        this.onWindowResize();
        this.init();
    }


    onWindowResize(): void {

        if (window.innerWidth < this.navBreakpoint) {
            this.$el?.classList.add('header-mobile');
            this.toggleMenu();
            this.toggleSubMenuMobile();

        } else if (window.innerWidth > this.navBreakpoint) {
            this.$el?.classList.remove('header-mobile');
            this.toggleSubMenuDesktop();
        }
    }

    init(): void {
        if (this.$el) {
            toggleClassWhenSticky(this.$el, 'main-header-sticky');
        }
    }

    toggleMenu(): void {
        const $burgerBtn = this.$el?.querySelector('.js-burger-btn');
        const $closeBtn = this.$el?.querySelector('.js-close-btn');
        const $mobileMenu = this.$el?.querySelector('.main-header-menu');
        const $subMenus = this.$el?.querySelectorAll('.has-submenu');

        $burgerBtn?.addEventListener('click', () => {
            $mobileMenu?.classList.add('main-header-menu--active');
            this.$body?.classList.add('prevent-scroll');
        });

        $closeBtn?.addEventListener('click', () => {
            $mobileMenu?.classList.remove('main-header-menu--active');
            this.$body?.classList.remove('prevent-scroll');
            $subMenus?.forEach(subMenu => subMenu.classList.remove('has-submenu--active'))

        })
    }

    toggleSubMenuDesktop(): void {
        const $subMenusBtns = this.$el?.querySelectorAll('.has-submenu .header-nav-link');
        let activeButton = null;

        $subMenusBtns?.forEach((subMenuBtn) => {
            subMenuBtn?.addEventListener('keydown', (event) => {
                if (event['key'] === ' ' || event['key'] === 'Spacebar' || event['keyCode'] === 32 || event['keyCode'] === 13 || event['key'] === 'Enter') {
                    // Barre d'espace enfoncée
                    event.preventDefault(); // Empêche le défilement de la page
                    if (subMenuBtn !== activeButton) {
                        // Un autre bouton a actuellement la classe active
                        if (activeButton) {
                            activeButton.classList.remove('active');
                        }
                        subMenuBtn.classList.add('active');
                        activeButton = subMenuBtn;
                    } else {
                        subMenuBtn.classList.remove('active');
                        activeButton = null
                    }
                }
            })
        })

        document.addEventListener('keydown', (event) => {
            if (event['key'] === 'Escape' || event['key'] === 'Esc' || event['keyCode'] === 27) {
                $subMenusBtns?.forEach((subMenuBtn) => {
                    subMenuBtn.classList.remove('active');
                    activeButton = null;
                });
            }
        });
    }

    toggleSubMenuMobile(): void {
        const $headerMobile = this.$body?.querySelector('.js-main-header.header-mobile');

        if ($headerMobile) {
            const $subMenus = $headerMobile.querySelectorAll('.header-nav-element.has-submenu');
            $subMenus?.forEach((subMenu) => {
                const link = subMenu.querySelector('.header-nav-link');
                link?.addEventListener('click', () => {
                    subMenu.classList.toggle('has-submenu--active');
                })
            })
        }
    }
}
