import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

export default function initCarouselVignettes() : void {
    const $carouselVignettesContainer = document.querySelectorAll<HTMLElement>('.block-carousel-vignettes');

    $carouselVignettesContainer.forEach($container => {
        new CarouselVignette($container);
    });
}

class CarouselVignette {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    $navigation_prev: HTMLElement | null | undefined;
    $navigation_next: HTMLElement | null | undefined;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.carousel-vignettes');
        this.$navigation_prev = $el?.querySelector('.button-prev');
        this.$navigation_next = $el?.querySelector('.button-next');
        this.init();
    }

    init(): void {
        
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                modules: [Navigation, Autoplay],
                slidesPerView: 'auto',
                loop: true,
                speed: 6000,
                autoplay: {
                    delay: 1,
                    disableOnInteraction: false, // Autorise l'autoplay à continuer après l'interaction de l'utilisateur
                },
                allowTouchMove: true,
                spaceBetween: 25,
                grabCursor: true,
                navigation: {
                    nextEl: this.$navigation_next,
                    prevEl: this.$navigation_prev,
                },
                breakpoints: {
                    768: {
                        spaceBetween: 30,
                    },
                },
            });
        }
    }
}
