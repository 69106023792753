import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';


export default function initGridPostsSwiper() : void {
    const $gridPostsSwiper = document.querySelectorAll<HTMLElement>('.grid-posts-swiper');

    $gridPostsSwiper.forEach($container => {
        new GridPostsSwiper($container);
    });
}

class GridPostsSwiper {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    $pagination: HTMLElement | null | undefined;
    mdBreakpoint: number;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$pagination = $el?.querySelector('.swiper-pagination');
        this.mdBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'));

        if(this.$el && this.$el.dataset.swiperDevice == 'mobile') {
            window.addEventListener('resize', this.onWindowResize.bind(this));
            this.onWindowResize();
        } else {
            this.createSwiper();
        }
    }

    /**
     * Événement lancé à chaque resize de la fenêtre
     * Détermine s'il faut créer ou détruire le swiper
     */
    onWindowResize(): void {
        if (this.swiper && window.innerWidth > this.mdBreakpoint) {
            // Détruit le swiper s'il est déjà créé et qu'on est au dessus du breakpoint md
            this.destroySwiper();
        } else if (!this.swiper && window.innerWidth <= this.mdBreakpoint) {
            // Créé le swiper s'il n'est pas déjà créé et q'uon est au dessous du breakpoint md
            this.createSwiper();
        }
    }

    /**
     * Créé le swiper
     */
    createSwiper(): void {
        if (this.$el) {
            this.swiper = new Swiper(this.$el, {
                modules: [Pagination],
                slidesPerView: 'auto',
                spaceBetween: 10,
                loop: false,
                allowTouchMove: true,
                grabCursor: true,
                pagination: {
                    el: this.$pagination,
                },
            });
        }
    }

    /**
     * Détruit le swiper
     */
    destroySwiper(): void {
        this.swiper?.destroy(true, true);
        this.swiper = null;
    }
}
