
export default function initVideo() {
    const $blocksVideo = document.querySelectorAll<HTMLElement>('.block-video');
    $blocksVideo.forEach($container => {
        new BlockVideo($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class BlockVideo {
    $el: HTMLElement | null | undefined;
    $video: HTMLVideoElement | null | undefined;
    $button: HTMLElement | null | undefined;
    $thumbnail: HTMLElement | null | undefined;
    
    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$video = this.$el?.querySelector('.js-video');
        this.$button = this.$el?.querySelector('.js-icon-play');
        this.$thumbnail = this.$el?.querySelector('.js-video-thumbnail');
 
        this.init();
    }

    init() {
        if (this.$button && this.$video && this.$thumbnail) {
            this.$button.addEventListener('click', () => {
                this.$thumbnail?.classList.add('hidden');
                this.$video?.play();
            })
        }
    }
}
