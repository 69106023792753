export default function initFormulaireCF7Redirection() {
    const $formRedirect = document.querySelectorAll<HTMLElement>('[data-redirect-form-url]');
    $formRedirect.forEach($container => {
        new FormRedirect($container);
    });
}


export class FormRedirect {
    $el: HTMLElement | null | undefined;
   
    constructor($el: HTMLElement | null) {
        this.$el = $el;
       
        this.init();
    }

    init(): void {
        const $url_redirection = this.$el.dataset.redirectFormUrl;
        if ($url_redirection) {
            document.addEventListener('wpcf7mailsent', () => {
                window.location.href = $url_redirection;
            });
        }
    }
}