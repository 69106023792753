import { ModalHTMLElement } from './modal';

export function initYoutubeIframe() {
    document.querySelectorAll<YoutubeIframeHTMLElement>('.js-youtube').forEach($el => {
        const videoId = <string>$el.dataset.id;
        const playerVars = {
            rel: 0,
        }
        if (videoId) {
            $el.youtubeIframe = new YoutubeIframe($el, videoId, playerVars);
        }
    });

    document.addEventListener('modalopen', evt => {
        const $modal = (evt as CustomEvent).detail as ModalHTMLElement;
        const $modalContent = $modal.querySelector<HTMLElement>('.js-modal-content');
        const $youtubeIframeContainer = $modalContent?.querySelector<YoutubeIframeHTMLElement>('.js-youtube');
        if ($youtubeIframeContainer && $youtubeIframeContainer.youtubeIframe) {
            $youtubeIframeContainer.youtubeIframe.player.playVideo();
        }
    });

    document.addEventListener('modalclose', evt => {
        const $modal = (evt as CustomEvent).detail as ModalHTMLElement;
        const $modalContent = $modal.querySelector<HTMLElement>('.js-modal-content');
        const $youtubeIframeContainer = $modalContent?.querySelector<YoutubeIframeHTMLElement>('.js-youtube');
        if ($youtubeIframeContainer && $youtubeIframeContainer.youtubeIframe) {
            $youtubeIframeContainer.youtubeIframe.player.pauseVideo();
        }
    });
}

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const YT: any;
/* eslint-enable @typescript-eslint/no-explicit-any */

interface YoutubeIframeHTMLElement extends HTMLElement {
    youtubeIframe: YoutubeIframe | null;
}

class YoutubeIframe {
    player: typeof YT.Player;
    constructor($el: YoutubeIframeHTMLElement, videoId: string, playerVars: object) {
        YT.ready(() => {
            this.player = new YT.Player($el.querySelector('.player'), {
                videoId,
                playerVars
            });
        });
    }
}
