type FocusManagerType = {
    trappedFocusEvent: CallableFunction,
    $focusableEls: HTMLElement[],
    trapFocus: CallableFunction,
    untrapFocus: CallableFunction,
};

const FocusManager: FocusManagerType = {
    trappedFocusEvent: () => { return; },
    $focusableEls: [],

    /**
     * Contraint le focus à l'intérieur d'un ou plusieurs éléments
     * 
     * @param $elements Éléments dans lesquels contraindre le focus
     */
    trapFocus($elements: HTMLElement[]) : typeof self {
        this.untrapFocus();

        $elements.forEach($element => {
            this.$focusableEls = this.$focusableEls.concat(Array.from($element.querySelectorAll('a, button, [tabindex], input')));
        });

        this.trappedFocusEvent = (evt: KeyboardEvent) : void => {
            if (evt.key !== 'Tab') {
                return;
            }

            // Si l'élément focus n'est pas présent dans la liste des éléments focusable,
            // on force le focus sur le premier ou dernier élément en fonction de si on
            // a appuyé sur la touche shift ou pas
            const isContained = $elements.filter($element => $element.contains(document.activeElement)).length > 0;
            if (!isContained) {
                const nextIndex = evt.shiftKey ? this.$focusableEls.length - 1 : 0;
                this.$focusableEls[nextIndex].focus();
            }
        };

        document.addEventListener('keyup', evt => this.trappedFocusEvent(evt));

        return self;
    },

    /**
     * Supprime la contrainte du focus s'il y en a déjà une
     */
    untrapFocus() : typeof self {
        if (this.trappedFocusEvent) {
            document.removeEventListener('keyup', (evt: KeyboardEvent) => this.trappedFocusEvent(evt));
            this.trappedFocusEvent = () => { return; };
        }

        this.$focusableEls = [];
        this.focusIndex = 0;

        return self;
    },
};

export default FocusManager;
