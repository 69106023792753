export default function initReadMore() {
    const $readMoreContainers = document.querySelectorAll<HTMLElement>('.js-readmore-container');
    $readMoreContainers.forEach($container => {
        new ReadMore($container);
    });
}

export class ReadMore {

    $container: HTMLElement | null | undefined;
    $openbutton: HTMLElement | null | undefined;
    $closebutton: HTMLElement | null | undefined;
    $content: HTMLElement | null | undefined;

    constructor($container: HTMLElement | null) {
        this.$container = $container;
        this.$openbutton = $container?.querySelector('.js-readmore-open-button');
        this.$closebutton = $container?.querySelector('.js-readmore-close-button');
        this.$content = $container?.querySelector('.js-readmore-content');

        this.init();
    }

    /**
     * Initialise les éléments du readmore
     *
     * @return {void}
     */
    init() {
        this.$openbutton?.addEventListener('click', () => {
            if (!this.$container?.classList.contains('open')) {
                this.openreadmore();
            }
        });
        this.$closebutton?.addEventListener('click', () => {
            if (this.$container?.classList.contains('open')) {
                this.closereadmore();
            }
        });
    }

    /**
     * Ouvre le readmore
     *
     * @return {void}
     */
    openreadmore() {
        this.$container?.classList.add('open');
        this.$openbutton?.classList.add('hidden');
        this.$closebutton?.classList.add('visible');
        this.$content?.setAttribute('aria-hidden', 'false');
    }

    /**
     * Ferme le readmore
     *
     * @return {void}
     */
    closereadmore() {
        this.$container?.classList.remove('open');
        this.$openbutton?.classList.remove('hidden');
        this.$closebutton?.classList.remove('visible');
        this.$content?.setAttribute('aria-hidden', 'true');
    }
}

