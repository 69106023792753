export default function initFormLivreBlanc() {
    const $formLivreBlancContainers = document.querySelectorAll<HTMLElement>('.js-form-livre-blanc');
    $formLivreBlancContainers.forEach($container => {
        new FormLivreBlanc($container);
    });
}


export class FormLivreBlanc {
    $el: HTMLElement | null | undefined;
    $inputFile: HTMLInputElement | null | undefined;
    $contentSuccess: HTMLElement | null | undefined;
    $ctaDownload: HTMLAnchorElement | null | undefined;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$inputFile = $el.querySelector('input[name="dl_file"]')
        this.$contentSuccess = document.querySelector('.js-download-file');
        if (this.$contentSuccess) {
            this.$ctaDownload = this.$contentSuccess.querySelector('.js-download-cta');
        }

        if (this.$inputFile && this.$contentSuccess && this.$ctaDownload) {
            this.init();
        }
    }

    init(): void {
        document.addEventListener('wpcf7mailsent', () => {
            this.$el.style.display = 'none';
            this.$contentSuccess.style.display = 'flex';
            this.$ctaDownload.href = this.$inputFile.value;
        });
    }
}