
export default function initInputCustom() {
    const $inputsCustom = document.querySelectorAll<HTMLElement>('.input-custom');
    $inputsCustom.forEach($input => {
        new InputCustom($input);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Input Custom Wrapper
 * @param {HTMLInputElement} $input        HTML Input Element Input field
 * @param {HTMLInputElement} $textarea       HTML Input Element Input field
 */
class InputCustom {
    $el: HTMLElement | null | undefined;
    $input: HTMLInputElement | null | undefined;
    $textarea: HTMLTextAreaElement | null | undefined;
    
    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$input = $el?.querySelector('input');
        this.$textarea = $el?.querySelector('textarea');
        
        if (this.$input) {
            this.init(this.$input);
        }

        if (this.$textarea) {
            this.init(this.$textarea);
            this.updateTextareaHeight(this.$textarea)
        }
    }

    init(elem: HTMLInputElement | HTMLTextAreaElement | null) {
        this.checkValue(elem);
        elem?.addEventListener('change', () => {
            this.checkValue(elem);
        });

        elem?.addEventListener('focus', () => {
            this.$el?.classList.add('focus');
        });

        elem?.addEventListener('blur', () => {
            this.$el?.classList.remove('focus');
        });
    }

    checkValue(elem: HTMLInputElement | HTMLTextAreaElement | null) {
        if (elem) {
            if (elem.value) {
                this.$el?.classList.add('filled');
            } else {
                this.$el?.classList.remove('filled');
            }
        }
    }

    updateTextareaHeight(elem: HTMLTextAreaElement | null) {
        if (elem) {
            elem.style.height = elem.scrollHeight+'px';

            if (elem.value == '') {
                elem.style.height = '62px';
            }
    
            elem.addEventListener('input', () => {
                elem.style.height = elem.scrollHeight+'px';
                if (elem.value == '') {
                    elem.style.height = '62px';
                }
            })
        }
    }
}
